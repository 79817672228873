import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

function Press({ power, position }) {
  console.log({ power, position });
  return (
    <svg id="machine" viewBox="0 0 512 512" className="animated">
      <g id="Page-1" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Pressa" fillRule="nonzero">
          {position === 'DOWN' && (
            <rect className="oggetto-enter" fill="#0093ee" x={110} y={303} width={80} height={40} />
          )}
          {position === 'UP' && <rect className="oggetto-exit" fill="#0093ee" x={110} y={333} width={80} height={10} />}
          <g id="stampo" className={classNames({ up: position === 'UP', down: power && position === 'DOWN' })}>
            <rect id="Rectangle-path" fill="#57565C" x={130} y={0} width={30} height={115} />
            <rect id="Rectangle-path" fill="#57565C" x={40} y={0} width={30} height={115} />
            <path
              d="M200,180 L0,180 L0,135 C0,115.701 15.701,100 35,100 L165,100 C184.299,100 200,115.701 200,135 L200,180 Z"
              id="Shape"
              fill="#263238"
            />
          </g>
          <g id="Base">
            <polygon id="Shape" fill="#C6C5CA" points="512 512 50 512 50 383 342 383 342 130 270 130 270 0 512 0" />
            <polygon
              id="Shape"
              fill="rgba(55, 71, 79, 0.6)"
              points="512 512 256 512 256 383 342 383 342 130 270 130 270 0 512 0"
            />
            <polygon id="Shape" fill="#3E3D42" points="300 453 0 453 0 343 300 343" />
            <polygon id="Shape" fill="#3E3D42" points="300 170 0 170 0 0 300 0" />
            <polygon id="Shape" fill="#2D2D30" points="300 170 256 170 256 0 300 0" />
            <polygon id="Shape" fill="#2D2D30" points="300 453 256 453 256 343 300 343" />
          </g>
          <circle id="Luce---Off" fill={power ? '#A51630' : '#ff1744'} cx={65} cy={65} r={15} />
          <circle id="Luce---On" fill={power ? '#00c853' : '#008744'} cx={105} cy={65} r={15} />
          <g
            id="Luce---Up"
            transform="translate(389.000000, 136.000000)"
            fill={position === 'UP' ? '#ffab00' : '#DC8D00'}
          >
            <circle id="Oval" cx={39} cy={90} r={15} />
            <polygon id="Shape" points="56.678 60.606 39 42.929 21.322 60.606 0.109 39.394 39 0.503 77.891 39.394" />
          </g>
          <g
            id="Luce---Down"
            transform="translate(388.000000, 271.000000)"
            fill={position === 'DOWN' ? '#ff1744' : '#A51630'}
          >
            <circle id="Oval" cx={39} cy={15} r={15} />
            <polygon id="Shape" points="39 104.497 0.109 65.606 21.322 44.394 39 62.071 56.678 44.394 77.891 65.606" />
          </g>
        </g>
      </g>
    </svg>
  );
}

Press.propTypes = {
  power: PropTypes.bool.isRequired,
  position: PropTypes.oneOf(['UP', 'DOWN']).isRequired,
};
export default Press;
